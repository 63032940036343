import storeJS from 'store'
const ID_TOKEN_KEY = 'id_token'

export const getToken = () => {
    return storeJS.get(ID_TOKEN_KEY)
}

export const saveToken = token => {
    storeJS.set(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
    storeJS.remove(ID_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken }
