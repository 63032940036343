
export const decimal = {
    validate(value) {
        return /^\s*-?\d+(\.\d{1,2})?\s*$/
            .test(value)
    },
    message: 'El campo {_field_} debe ser númerico e incluir 2 decimales.',
}

export const decimalAny = {
    validate(value) {
        return /^\s*-?\d+(\.\d{1,20})?\s*$/
            .test(value)
    },
    message: 'El campo {_field_} debe ser númerico y puede incluir decimales.',
}

export const url = {
    validate(value) {
        // eslint-disable-next-line no-useless-escape
        return  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
            .test(value)
    },
    message: 'El campo {_field_} debe ser un url válido.',
}