import Vue from 'vue'
// import './plugins/axios'
import App from './App.vue'
import { buildRouter } from './router/index'
import store from './store'

import ApiService from './services/api.service'
// import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'

// Vue 3rd party plugins
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import PerfectScrollbar from 'perfect-scrollbar'
import './plugins/bootstrap-vue'
import './plugins/element-ui'
import './plugins/vee-validate'
import './plugins/dayjs'
import './plugins/inline-svg'
import './plugins/perfect-scrollbar'
import './plugins/app-config'
import './plugins/app-config'

window.PerfectScrollbar = PerfectScrollbar

// API service init
ApiService.init()

// Remove this to disable mock API
// MockService.init()

Vue.config.productionTip = false

buildRouter().then(router => {
  new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: h => h(App),
  }).$mount('#app')
})
