import Vue from 'vue'
import { Upload, Loading, Message, Dialog, Popover, Popconfirm, Button, Avatar  } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Popover)
Vue.use(Popconfirm)
Vue.use(Button)
Vue.use(Avatar)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
