import ApiService from '@/services/api.service'
import { API_URL } from '@/config/index'

const configApiHost = API_URL('CONFIG')


export default {

    index() {
        return ApiService.get(`${configApiHost}`)
    },

    bannersList() {
        return ApiService.get(`${configApiHost}/banners`)
    },

    bannerCreate(banner) {
        return ApiService.post(`${configApiHost}/banners/`, banner)
    },

    bannerUpdate(banner, bannerId) {
        return ApiService.put(`${configApiHost}/banners/${bannerId}`, banner)
    },

    bannerDelete(bannerId) {
        return ApiService.delete(`${configApiHost}/banners/${bannerId}`)
    },

}
