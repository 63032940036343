
import ApiService from '@/services/api.service'
import { API_URL, API_KEY } from '@/config'

const usersService = API_URL('USERS')
const usersXApiKey = API_KEY('USERS')

export default {
    index() {
        return ApiService.axios().get(`${usersService}/`, { headers: {
            'x-api-key': usersXApiKey,
        }})
    },

    create(payload) {
        return ApiService.axios().post(`${usersService}/`, null, { headers: {
            'x-api-key': usersXApiKey,
        }}, payload)
    },

    show(id) {
        return ApiService.axios().post(`${usersService}/${id}`, null, { headers: {
            'x-api-key': usersXApiKey,
        }})
    },

    update(payload, id) {
        return ApiService.axios().put(`${usersService}/${id}`, payload, { headers: {
            'x-api-key': usersXApiKey,
        }})
    },

    delete(id) {
        return ApiService.axios().delete(`${usersService}/${id}`, { headers: {
            'x-api-key': usersXApiKey,
        }}, null)
    },

    profile(payload) {
        return ApiService.axios().post(`${usersService}/profile`, payload, { headers: {
            'x-api-key': usersXApiKey,
        }})
    },

    verify(email) {
        return ApiService.axios().get(`${usersService}/verify/${email}`)
    },

    mailingSuggestedProducts(mailData) {
        return ApiService.axios().post(`${usersService}/mailing/suggested_product`, mailData, { headers: {
            'x-api-key': usersXApiKey,
        }})
    },

}
