import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import { required, email, min, max, numeric, integer, confirmed, is, is_not, regex } from 'vee-validate/dist/rules'
import { decimal, decimalAny, url } from '@/plugins/validation-rules'
import es from 'vee-validate/dist/locale/es.json'

extend('required', required)
extend('numeric', numeric)
extend('integer', integer)
extend('min', min)
extend('max', max)
extend('email', email)
extend('confirmed', confirmed)
extend('is', is)
extend('is_not', is_not)
extend('decimal', decimal)
extend('decimalAny', decimalAny)
extend('regex', regex)
extend('url', url)

localize('es', es)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
