// USA
export const locale = {
    TRANSLATOR: {
        SELECT: 'Select your language',
    },
    MENU: {
        NEW: 'nuevo',
        ACTIONS: 'Comportamiento',
        CREATE_POST: 'Crear nueva publicación',
        PAGES: 'Pages',
        FEATURES: 'Caracteristicas',
        APPS: 'Aplicaciones',
        DASHBOARD: 'Panel',
        PRODUCTS: 'Productos',
        CATEGORIES: 'Categorias',
        CAMPAINGS: 'Campaings',
        COUPONS: 'Cupones',
        DISTRCITS: 'Distritos',
        SUPPLIERS: 'Proveedores',
        IMAGES: 'Imágenes',
        IMPORT: 'Importar',
        ORDERS: 'Ordenes',
        USERS: 'Usuarios',
        CUSTOMERS: 'Pacientes',
        APPOINTMENTS: 'Citas',
        RATINGS: 'Calificaciones',
        BILLING: 'Facturación',
        SETTINGS: 'Preferencias',
        USER_PROFILE: 'Mi Perfil',
    },
    AUTH: {
        GENERAL: {
            OR: 'Or',
            SUBMIT_BUTTON: 'Submit',
            NO_ACCOUNT: 'Don\'t have an account?',
            SIGNUP_BUTTON: 'Sign Up',
            FORGOT_BUTTON: 'Forgot Password',
            BACK_BUTTON: 'Back',
            PRIVACY: 'Privacy',
            LEGAL: 'Legal',
            CONTACT: 'Contact',
        },
        LOGIN: {
            TITLE: 'Login Account',
            BUTTON: 'Sign In',
        },
        FORGOT: {
            TITLE: 'Forgot Password?',
            DESC: 'Enter your email to reset your password',
            SUCCESS: 'Your account has been successfully reset.',
        },
        REGISTER: {
            TITLE: 'Sign Up',
            DESC: 'Enter your details to create your account',
            SUCCESS: 'Your account has been successfuly registered.',
        },
        INPUT: {
            EMAIL: 'Email',
            FULLNAME: 'Fullname',
            PASSWORD: 'Password',
            CONFIRM_PASSWORD: 'Confirm Password',
            USERNAME: 'Username',
        },
        VALIDATION: {
            INVALID: '{{name}} is not valid',
            REQUIRED: '{{name}} is required',
            MIN_LENGTH: '{{name}} minimum length is {{min}}',
            AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'The login detail is incorrect',
            REQUIRED_FIELD: 'Required field',
            MIN_LENGTH_FIELD: 'Minimum field length:',
            MAX_LENGTH_FIELD: 'Maximum field length:',
            INVALID_FIELD: 'Field is not valid',
        },
    },
    ECOMMERCE: {
        COMMON: {
            SELECTED_RECORDS_COUNT: 'Selected records count: ',
            ALL: 'All',
            SUSPENDED: 'Suspended',
            ACTIVE: 'Active',
            FILTER: 'Filter',
            BY_STATUS: 'by Status',
            BY_TYPE: 'by Type',
            BUSINESS: 'Business',
            INDIVIDUAL: 'Individual',
            SEARCH: 'Search',
            IN_ALL_FIELDS: 'in all fields',
        },
        ECOMMERCE: 'eCommerce',
        CUSTOMERS: {
            CUSTOMERS: 'Customers',
            CUSTOMERS_LIST: 'Customers list',
            NEW_CUSTOMER: 'New Customer',
            DELETE_CUSTOMER_SIMPLE: {
                TITLE: 'Customer Delete',
                DESCRIPTION: 'Are you sure to permanently delete this customer?',
                WAIT_DESCRIPTION: 'Customer is deleting...',
                MESSAGE: 'Customer has been deleted',
            },
            DELETE_CUSTOMER_MULTY: {
                TITLE: 'Customers Delete',
                DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                WAIT_DESCRIPTION: 'Customers are deleting...',
                MESSAGE: 'Selected customers have been deleted',
            },
            UPDATE_STATUS: {
                TITLE: 'Status has been updated for selected customers',
                MESSAGE: 'Selected customers status have successfully been updated',
            },
            EDIT: {
                UPDATE_MESSAGE: 'Customer has been updated',
                ADD_MESSAGE: 'Customer has been created',
            },
        },
    },
    "PRODUCTOS": {
        "nombre": "name",
        "categoría": "category",
        "unidad": "category",
        "precio": "price",
        "stock": "stock",
        "activo": "active",
        "slug": "slug",
        "actions": "actions",
        "editar item": "edit item",
        "ver link del producto": "show product link",
        "distrito": "district",
        "link del producto": "product link",
        "proveedor": "supplier",
        "categorías": "categories",
        "descripción": "description",
        "tipo de descuento": "discount type",
        "cantidad máxima permitida": "maximum quantity allowed",
        "variantes del producto": "product variants",
        "añadir": "add",
        "imagen": "image",
        "cancelar": "cancel",
        "guardar": "save",
        "nuevo item": "new item",
        "refrescar": "refresh",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search",
    },
    "PROVEEDORES": {
        "ein": "ein",
        "nombre": "name",
        "razón social": "business name",
        "nombre comercial": "comercial name",
        "teléfono": "phone",
        "contacto": "contact",
        "monto delivery gratis": "free delivery amount",
        "monto mínimo": "minimum amount",
        "tiempo de delivery": "delivery time",
        "autorizado": "authorized",
        "activo": "active",
        "actions": "actions",
        "catgeorías": "categories",
        "zonas de delivery": "delivery zones",
        "estado": "state",
        "condado": "county",
        "ciudades": "cities",
        "costos de delivery": "delivery rates",
        "landing personalizado": "custom landing",
        "activar landing personalizado": "activate custom landing",
        "el enlace se habilitará una vez se hayan guardado los cambios": "the url would be enabled once the changes are saved",
        "imagen de fondo": "background image",
        "imagen de fondo mobile": "background image mobile",
        "color primario": "primary color",
        "color secundario": "secondary color",
        "color terceario": "terteary color",
        "link de autorización de Mercado Pago": "Mercado Pago Authorization link",
        "imagen": "image",
        "cancelar": "cancel",
        "guardar": "save",
        "nuevo item": "new item",
        "refrescar": "refresh",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search",
    },
    "CATEGORIAS": {
        "nombre": "name",
        "padre": "parent",
        "orden": "order",
        "url": "url",
        "activo": "active",
        "actions": "actions",
        "icono": "icon",
        "cancelar": "cancel",
        "guardar": "save",
        "nuevo item": "new item",
        "refrescar": "refresh",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search",
    },
    "DISTRITOS": {
        "distritos": "disctricts",
        "nombre": "name",
        "departamento": "department",
        "orden": "order",
        "url": "url",
        "actions": "actions",
        "activo": "active",
        "nuevo item": "new item",
        "refrescar": "refresh",
        "buscar": "search",
    },
    "CUPONES": {
        "cupones": "coupons",
        "campañas": "campaings",
        "código": "code",
        "nombre": "name",
        "activo": "active",
        "creación": "created",
        "actualización": "updated",
        "actions": "actions",
        "campaña": "campaign",
        "monto": "amount",
        "expiración": "exipration",
        "fecha de expiración": "expiration date",
        "cancelar": "cancel",
        "guardar": "save",
        "nuevo item": "new item",
        "refrescar": "refresh",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search",
    },
    "FACTURACIÓN": {
        "id": "id",
        "proveedor": "supplier",
        "contacto": "contact",
        "teléfono": "phone",
        "email": "email",
        "ein": "ein",
        "total": "total",
        "fee": "fee",
        "facturado": "billed",
        "actions": "actions",
        "cancelar": "cancel",
        "guardar": "save",
        "nuevo item": "new item",
        "refrescar": "refresh",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search",
    },
    "IMPORTAR": {
        "importar": "import",
        "arrastra o elige los archivos con el formato de plantilla CSV a importar": "drag or choose the files with the CSV template format to import",
        "proveedor": "supplier",
        "crear nuevo proveedor": "create supplier",
        "sube tu archivo aquí o haz click para cargar": "drag your file here or click to load",
        "solo archivos csv con tamaño menor de 500kb": "only files under 500kb",
        "subir": "upload",
    },
    "ORDERNES": {
        "ordenes": "orders",
        "id": "id",
        "cración": "created",
        "cliente": "customer",
        "total": "total",
        "estado": "status",
        "email": "email",
        "id usuario": "userId",
        "proveedores": "suppliers",
        "items": "items",
        "actions": "actions",
        "ver detalles": "show details",
        "número de pedido": "order number",
        "estados": "statuses",
        "id transacción": "transaction id",
        "N de proveedores": "suppliers number",
        "N de productos": "products number",
        "datos del usuario": "user data",
        "nombres": "name",
        "apellidos": "lastname",
        "teléfono": "phone",
        "tipo de documento": "document type",
        "N de documento": "document number",
        "dirección": "address",
        "referencia": "reference",
        "id de carrito": "cart id",
        "fecha de creación": "creation date",
        "fecha de actualización": "updated date",
        "detalle de pedido": "order detail",
        "nombre": "name",
        "precio": "price",
        "quantity": "cantidad",
        "unidades": "units",
        "cerrar": "close",
        "cancelar": "cancel",
        "save": "guardar",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search",
    },
    "USUARIOS": {
        "usuarios": "users",
        "nombres": "name",
        "apellidos": "lastname",
        "email": "email",
        "teléfono": "phone",
        "tipo de documento": "document type",
        "N de documento": "document number",
        "contraseña": "password",
        "actions": "actions",
        "confirmar contraseña": "confirm password",
        "activo": "active", 
        "cerrar": "close",
        "cancelar": "cancel",
        "save": "guardar",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search",
    },
    "PREFERENCIAS": {
        "preferencias": "settings",
        "general": "general",
        "banners": "banners",
        "mailings": "mailings",
        "título y meta descripción": "Title & meta description",
        "El título y la meta descripción ayudan a definir cómo aparece tu tienda en los motores de búsqueda.": "El título y la meta descripción ayudan a definir cómo aparece tu tienda en los motores de búsqueda.",
        "título": "title",
        "logo": "logo",
        "descripcion": "description",
        "keywords SEO": "keywords SEO",
        "Agrega palabras clave que identifiquen el sitio web.": "Add keywords that identify the website.",
        "selecciona un idioma": "select a language",
        "selecciona una moneda": "select a currency",
        "textos de información y legales": "info texts and legals",
        "Agrega o edita los textos de información y Legales para el sitio web": "Add or edit the information and Legal texts for the website.",
        "quienes somos": "who we are",
        "términos y condiciones clientes": "Clients terms and conditions",
        "términos y condiciones proveedores": "Suppliers terms and conditions",
        "poliíica de privacidad": "privacy policy",
        "logo payment gateway": "payment gateway logo",
        "contacto": "contact",
        "ayuda": "help",
        "banners home": "home banners",
        "Agrega imágenes, titulos y descripciones para promocionar en el Home.": "Add images, titles and descriptions to promote on the Home.",
        "nuevo banner": "new banner",
        "eliminar": "delete",
        "activo": "active",
        "descripción": "description",
        "texto de botón": "button label",
        "orden": "order",
        "enalce": "link",
        "generado": "generated",
        "manual": "manual",
        "imagen desktop": "desktop image",
        "distrito": "district",
        "marca": "brand"
    },
    "CALIFIFACIONES": {
        "calificaciones": "reviews",
        "id": "id",
        "proveedor": "supplier",
        "usuario": "user",
        "calificación": "score",
        "tipo": "type",
        "comentario": "comment",
        "creación": "created",
        "actualización": "updated",
        "activo": "active", 
        "cerrar": "close",
        "cancelar": "cancel",
        "save": "guardar",
        "exportar todo a excel": "export all to excel",
        "export selected to excel": "export selected to excel",
        "buscar": "search"
    },
    "PERFIL": {
        "mi perfil": "my profile",
        "editar pefil": "edit profile",
        "desloguearse": "logout",
        "mis datos de usuario": "my user data",
        "nombres": "name",
        "apellidos": "lastname",
        "email": "email",
        "cambiar contraseña": "change password",
        "nueva contraseña": "new password",
        "confirmar nueva contraseña": "confrim new password",
        "guardar datos": "save data"
    }
}
