import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('America/Lima')

Vue.prototype.$day = dayjs
