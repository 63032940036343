export const toBoolean = (val) => {
    var TRUTHY_VALUES = 'y yes true'.split(/\s/)
    let value = null

    value = val.toString()
    value = val.trim()
    value = val.toLowerCase()
  
    // Empty string is considered a falsy value
    if(!value.length) {
        return false
  
        // Any number above zero is considered a truthy value
    } else if(!isNaN(Number(value))) {
        return value > 0
  
        // Any value not marked as a truthy value is automatically falsy
    } else {
        return TRUTHY_VALUES.indexOf(value) >= 0
    }
}
  