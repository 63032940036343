import Vue from 'vue'
import Router from 'vue-router'
import ConfigAPI from '@/services/api/config.js'
import TreeModel from 'tree-model'
import store from '@/store'
import { VERIFY_AUTH } from '@/store/auth.module'
import { RESET_LAYOUT_CONFIG } from '@/store/config.module'
import { capitalize } from '@/utils/string'
import StaticRoutes from './staticRoutes.json'

Vue.use(Router)

let router = {}

export const getRoutesConfig = async() => {
    let routes = []
    try {
        ({ data: { data: { config: { routes } } } } = await ConfigAPI.index())
    } catch (error) {
        console.log(error)
    }
    return routes
}

export const generateRoutes = (routesNode) => {
    const routesTree = new TreeModel()
    const rootNode = routesTree.parse(routesNode)
    rootNode.all( /* predicate */ ).forEach((node) => { // rootNode.walk({strategy: 'breadth'}, (node) => {
        const route = node.model

        if (route.meta && route.meta.types && !route.meta.types.find(t => t.id === store.getters.currentUser.type)) {
            node.drop()
            return false
        }
        if (typeof route.componentPath === 'string') {
            const path = route.path === '/' ? '' : 'pages/'
            let componentPath = route.componentPath
            try {
                let componentRoutePath = route.componentPath.match(/.*\//gm) && route.componentPath.match(/.*\//gm)[0]
                let componentName = capitalize(route.componentPath.substring(route.componentPath.lastIndexOf('/') + 1))
                componentPath = componentRoutePath ?  componentRoutePath + componentName : componentName
            } catch (error) {
                componentPath = route.componentPath
            }
            route.component = () => import(
                /* webpackChunkName: "[request]-[index]" */
                // eslint-disable-next-line comma-dangle
                '@/views/' + path + componentPath
            )
        }
    })

    return rootNode.model
}

export const getStaticRoutes = () => {
    return StaticRoutes
}

export const generateRoutesTree = async() => {
    let routes = [
        {
            path: '/',
            redirect: 'login',
            component: () => import('@/views/pages/auth/Auth'),
            children: [
                {
                    name: 'login',
                    path: '/login',
                    component: () => import('@/views/pages/auth/Login'),
                },
                {
                    name: 'register',
                    path: '/register',
                    component: () => import('@/views/pages/auth/Register'),
                },
            ],
        },
        {
            path: '*',
            redirect: '/404',
        },
        {
        // the 404 route, when none of the above matches
            path: '/404',
            name: '404',
            component: () => import('@/views/pages/error/Error-1.vue'),
        },
    ]

    if (store.getters.isAuthenticated) {
        const routesNode = await getRoutesConfig()
        routesNode.children = [ ...getStaticRoutes() , ...routesNode.children ] 
        routes = [ generateRoutes(routesNode), ...routes ]
    }

    return routes
}

export const buildRouter = async () => {

    const routes = await generateRoutesTree()

    router = new Router({ routes })

    router.beforeEach((to, from, next) => {
        
        // Ensure we checked auth before each page load.
        Promise.all([store.dispatch(VERIFY_AUTH)]).then(next)

        // reset config to initial state
        store.dispatch(RESET_LAYOUT_CONFIG)
        
        // Scroll page to top on every route change
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)
    })


    router.afterEach(async () => {
        if (typeof window === 'undefined') {
            return false
        }
        const hamburguerButton = document.querySelector('.burger-icon-left.mobile-toggle-active') 
        hamburguerButton && hamburguerButton.click()
    })

    return router
}

export const updateRouter = async () => {
    const routes = await generateRoutesTree()
    router.matcher = new Router({ routes: [] }).matcher
    router.addRoutes(routes)
}


export default buildRouter

