import ReportsAPI from '@/services/api/reports'

export default {
    namespaced: true,

    state: {
        all: [],
    },
    getters: {
        all: state => state.all,
    },
    mutations: {
        setAll(state, reports) {
            state.all = reports
        },
    },
    actions: {
        async getAll({ commit }) {
            try {
                const response = await ReportsAPI.index()
                commit('setAll', response.data)
                return response
            } catch (error) {
                console.log(error)
                return Promise.reject(error)
            }
        }, 
    },
}

