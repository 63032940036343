const storeJS = require('store')
const USER_KEY = 'user'

export const getUser = () => {
    return storeJS.get(USER_KEY)
}

export const saveUser = token => {
    storeJS.set(USER_KEY, token)
}

export const destroyUser = () => {
    storeJS.remove(USER_KEY)
}

export default { getUser, saveUser, destroyUser }
