import { toBoolean } from '@/utils/formatter'

export const WEB = {
    MAIN_ADDRESS: 'Av Del Pinar 124 Oficina 405 Chacarilla del Estanque',
    USER_TYPES: {
        PATIENT: 2,
        DENTIST: 3,
    }
}

export const APP_PROTOCOL = process.env.VUE_APP_PROTOCOL
export const APP_HOST = process.env.VUE_APP_HOST
export const APP_PORT = process.env.VUE_APP_PORT || ''
export const BASE_URL = `${process.env.BASE_URL}`
export const SITE_BASE_URL = `${process.env.VUE_APP_SITE_BASE_URL}`
export const ENVIRONMENT = process.env.NODE_ENV

export const APP_EXTERNAL_PROVIDER_API_URL = process.env.APP_EXTERNAL_PROVIDER_API_URL
export const APP_EXTERNAL_PROVIDER_API_PROXY = process.env.APP_EXTERNAL_PROVIDER_API_PROXY

export const API_MIDDLEWARE_BASEURL = process.env.API_MIDDLEWARE_BASEURL

export const APP_NAME = process.env.VUE_APP_APP_NAME
export const APP_TITLE = process.env.VUE_APP_APP_TITLE
export const APP_DESCRIPTION = process.env.VUE_APP_APP_DESCRIPTION

export const API_PROTOCOL = process.env.VUE_APP_API_PROTOCOL
export const API_HOST = process.env.VUE_APP_API_HOST
export const API_PORT = process.env.VUE_APP_API_PORT || ''
export const API_STAGE = process.env.VUE_APP_API_STAGE

export const API_USE_SUBDOMAINS = process.env.VUE_APP_API_USE_SUBDOMAINS

export const API_IDS = {
    API_AUTH_ID: { id: process.env.VUE_APP_API_AUTH_ID, key: process.env.VUE_APP_API_AUTH_KEY },
    API_IMAGES_ID: { id: process.env.VUE_APP_API_IMAGES_ID, key: process.env.VUE_APP_API_IMAGES_KEY },
    API_TRANSACTION_ID: { id: process.env.VUE_APP_API_TRANSACTION_ID, key: process.env.VUE_APP_API_TRANSACTION_KEY },
    API_USERS_ID: { id: process.env.VUE_APP_API_USERS_ID, key: process.env.VUE_APP_API_USERS_KEY },
    API_CONFIG_ID: { id: process.env.VUE_APP_API_CONFIG_ID, key: process.env.VUE_APP_API_CONFIG_ID },
    API_CLIENTS: { id: process.env.VUE_APP_API_CLIENTS, key: process.env.VUE_APP_API_CLIENTS_KEY },
    API_BRANCHES_ID: { id: process.env.VUE_APP_API_BRANCHES_ID, key: process.env.VUE_APP_API_BRANCHES_ID },
    API_SERVICES_ID: { id: process.env.VUE_APP_API_SERVICES_ID, key: process.env.VUE_APP_API_SERVICES_ID },
    API_APPOINTMENTS_ID: { id: process.env.VUE_APP_API_APPOINTMENTS_ID, key: process.env.VUE_APP_API_APPOINTMENTS_ID },
}

export const API_KEY = (resource) => {
    const resourceKey = `API_${resource.toUpperCase()}_ID`
    return API_IDS[resourceKey].key || ''
}

export const API_URL = (resource = '') => {
    if (!toBoolean(API_USE_SUBDOMAINS)) {
        return `${API_PROTOCOL}://${API_HOST}${API_STAGE ? '/' + API_STAGE : ''}/${resource.toLowerCase()}` 
    }
    const resourceKey = `API_${resource.toUpperCase()}_ID`
    return `${API_PROTOCOL}://${API_IDS[resourceKey].id}.${API_HOST}${API_STAGE ? '/' + API_STAGE : ''}/` 
}

export const API_MIDDLEWARE_URL = (path = '') => {
    return `${API_MIDDLEWARE_BASEURL}${path}`
}


export const EXTERNAL_PROVIDER_API_PROXY = process.env.VUE_APP_EXTERNAL_PROVIDER_API_PROXY

export const S3_API_PROTOCOL = process.env.VUE_APP_S3_API_PROTOCOL
export const S3_API_PREFIX = process.env.VUE_APP_S3_API_PREFIX
export const S3_API_REGION = process.env.VUE_APP_S3_REGION
export const S3_API_HOST = process.env.VUE_APP_S3_API_HOST

export const S3_API_URL = bucket => `${S3_API_PROTOCOL}://${bucket}.${S3_API_PREFIX}.${S3_API_REGION}.${S3_API_HOST}`

export const S3_SETTINGS = {
    S3_KEY_ID: process.env.VUE_APP_S3_ACCESS_KEY_ID,
    S3_SECRET_ACCESS_KEY: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
    S3_REGION: process.env.VUE_APP_S3_REGION,
    S3_URL: (bucket) => S3_API_URL(bucket),
}

export const APP_S3_BUCKETS = {
    import: process.env.VUE_APP_APP_IMPORT_BUCKET,
    images: process.env.VUE_APP_APP_IMAGES_BUCKET,
}

export const CDN_IMAGES_URL = process.env.VUE_APP_APP_CDN_IMAGES_URL


export default {

    APP_NAME,
    APP_PROTOCOL,
    APP_HOST,
    APP_PORT,
    BASE_URL,

    API_PROTOCOL,
    API_HOST,
    API_PORT,
    API_URL,
    API_KEY,
    API_MIDDLEWARE_URL,
    CDN_IMAGES_URL,
}
