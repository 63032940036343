import ApiService from '@/services/api.service'
import { API_URL } from '@/config/index'

const suppliersApiHost = API_URL('SUPPLIERS')


export default {
    index() {
        return ApiService.get(`${suppliersApiHost}/`)
    },

    create(payload) {
        return ApiService.post(`${suppliersApiHost}/`, payload)
    },

    show(id) {
        return ApiService.get(`${suppliersApiHost}/${id}`)
    },

    update(payload, id) {
        return ApiService.put(`${suppliersApiHost}/${id}`, payload)
    },

    delete(id) {
        return ApiService.delete(`${suppliersApiHost}/${id}`)
    },

}
