import ApiService from '@/services/api.service'
import { API_URL } from '@/config'

const authService = API_URL('AUTH')
const usersService = API_URL('USERS')

export default {
    login(payload) {
        return ApiService.post(`${authService}/login`, payload)
    },
    logout(token) {
        return ApiService.post(`${authService}/logout`, { token })
    },
    register(form) {
        return ApiService.post(`${usersService}/users`, form)
    },
    passwordRecoveryRequest(payload) {
        return ApiService.post(`${authService}/password-recovery-request`, payload)
    },

}
