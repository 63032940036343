import ApiService from '@/services/api.service'
import AuthApi from '@/services/api/auth'
import UserApi from '@/services/api/users'
import SuppliersApi from '@/services/api/suppliers'
import JwtService from '@/services/jwt.service'
import UserService from '@/services/user.service'
import { updateRouter } from '@/router/index.js'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_USER = 'updateUser'
export const UPDATE_USER_SUPPLIER = 'updateUserSupplier'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_USER_SUPPLIER = 'setUserSupplier'
export const SET_ERROR = 'setError'

const state = {
    errors: null,
    user: UserService.getUser() || {},
    token: JwtService.getToken(),
    isAuthenticated: !!JwtService.getToken(),
    userTypes: [
        { id: 1, name: 'Administrador' },
        { id: 2, name: 'Proveedor' },
    ],
}

const getters = {
    currentUser(state) {
        return state.user
    },
    currenToken: state => state.token,
    userTypes: state => state.userTypes,
    isAuthenticated(state) {
        return state.isAuthenticated
    },
    isAdmin(state, getters) {
        return getters.currentUser.type === 1
    },
}

const actions = {
    async [LOGIN](context, credentials) {
        try {
            console.log(credentials)
            const { data } = await AuthApi.login(credentials)

            context.commit(SET_AUTH, data)

            return Promise.resolve(data)

        } catch (error) {
            const response = error.response
            if (response.data && response.data.message && response.data.message === 'Usuario no existe') {
                response.data.message = 'Usuario o contraseña incorrectos'
            }
            console.log(response && (response.data.errors || [response.data.message]))

            context.commit(SET_ERROR, response && (response.data.errors || [response.data.message]))
            return Promise.reject(error)
        }
    },
    async [LOGOUT](context) {
        await updateRouter()
        context.commit(PURGE_AUTH)
    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post('users', { user: credentials })
                .then(({ data }) => {
                    context.commit(SET_AUTH, data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response && (response.data.errors || [response.data.message]))
                    reject(response)
                })
        })
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {

            // updateRouter()
            // ApiService.setHeader()
            // ApiService.get('verify')
            //     .then(({ data }) => {
            //         context.commit(SET_AUTH, data)
            //     })
            //     .catch(({ response }) => {
            //         context.commit(SET_ERROR, response.data && response.data.errors)
            //     })
        } else {
            context.commit(PURGE_AUTH)
        }
    },
    async [UPDATE_USER](context, user) {
        try {
            const { data } = await UserApi.update(user, user.id)
            const token = context.getters.currenToken
            context.commit(SET_AUTH, { user, token })
            return data
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    },
    async [UPDATE_USER_SUPPLIER](context, userSupplier) {
        try {
            const { data } = await SuppliersApi.update(userSupplier, userSupplier.id)
            context.commit(SET_USER_SUPPLIER, { userSupplier })
            return data
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    },
}

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [SET_AUTH](state, { user, token }) {
        state.isAuthenticated = true
        state.user = user
        state.errors = {}
        UserService.saveUser(user)
        JwtService.saveToken(token)
    },
    [SET_USER_SUPPLIER](state, { userSupplier }) {
        state.user.supplier = userSupplier
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false
        state.user = {}
        state.errors = {}
        UserService.destroyUser()
        JwtService.destroyToken()
    },
}

export default {
    state,
    actions,
    mutations,
    getters,
}
