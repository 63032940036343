/**
 * string capitalization - first letter - capital, other - lowercase.
 * @param {String} word - Word or sentence.
 */
export const capitalize = (word) => {
    return `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`
}

/**
   * Strting camelization - underscores, dividing words replaces to Capital letters
   * sound_text => soundText.
   * @param {String} text - Word or sentence
   * @param {String} separator - delimiter, '_' by default
   */
export const camelize = (text, separator = '_') => text
    .split(separator)
    .reduce((acc, cur) => `${acc}${cur.charAt(0).toUpperCase() + cur.slice(1)}`, '')

export const lastWord = (string) => {
    if (!string) { return string }
    const n = string.trim().split(' ')
    return n[n.length - 1]
}

export const removeLastWord = (string) => {
    if (!string) { return string }
    const lastIndex = string.trim().lastIndexOf(' ')
    return string.substring(0, lastIndex)
}

export const firstWord = (string) => {
    if (!string) { return string }
    const n = string.trim().split(' ')
    return n[0]
}

export const removeFirstWord = (string) => {
    if (!string) { return string }
    const lastIndex = string.trim().indexOf(' ')
    return string.substring(lastIndex + 1)
}

export const firstLetter = (string) => {
    if (!string) { return string }
    return string.charAt(0)
}

// Generate unique IDs for use as pseudo-private/protected names.
// Similar in concept to
// <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
//
// The goals of this function are twofold:
//
// * Provide a way to generate a string guaranteed to be unique when compared
//   to other strings generated by this function.
// * Make the string complex enough that it is highly unlikely to be
//   accidentally duplicated by hand (this is key if you're using `ID`
//   as a private/protected name on an object).
//
// Use:
//
//     const privateName = ID();
//     const o = { 'public': 'foo' };
//     o[privateName] = 'bar';
export const randomID = () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9)
}

export const fileExtension = (str) => {
    const re = /(?:\.([^.]+))?$/
    return re.exec(str)[1]
}

export const removeFileExtension = (str) => {
    return str.replace(/\.[^/.]+$/, '')
}

export const slugify = (text) => {
    return text
        .toString()
        .normalize( 'NFD' )                   // split an accented letter in the base letter and the acent
        .replace( /[\u0300-\u036f]/g, '' )   // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\w\-]+/g, '')
        // eslint-disable-next-line no-useless-escape
        .replace(/\-\-+/g, '-') 
}

export const uniqueFileName = (fileNameWithExt) => {
    const name = slugify(removeFileExtension(fileNameWithExt))
    const extension = fileExtension(fileNameWithExt)
    const timestamp = Date.now()
    const fileName = `${name}-${timestamp}`
    return { fileName, extension, fileNameWithExtension: `${fileName}.${extension}` }
}

export const decimalPlaces = (num, places) => {
    return (Math.round(Number(num) * 100) / 100).toFixed(places)
} 